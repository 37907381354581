<template>
  <main>
    <package-header-defected title="Submit" subTitle="12764 Smith"></package-header-defected>

    <div class="container-fluid">
      <div class="row">
        <div class="col">

          <div class="mb-3 border-bottom pb-3">
            <!-- Back to applications link -->
            <router-link to="/pages/applications">
              <svg class="icon icon-arrow_cta_back mr-sm-1"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta_back"></use></svg>
              <span class="d-none d-sm-inline-block">Return to Applications</span>
              <span class="d-sm-none">Back</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">

          <!-- Create package form -->
          <form>
            <div class="form-group">
              <label for="name">Enter the required amount for submitting a Corrective Declaration.
                <a class="text-tertiary ml-1" href="#" data-toggle="popover" data-placement="right" data-title="Help Text" data-content="This is an example of some help text" v-popover>
                  <svg class="icon icon-help"><use xlink:href="/icons/symbol-defs.svg#icon-help"></use></svg>
                </a>
              </label>

              <input type="text" class="form-control" id="correctiveDeclaration" name="correctiveDeclaration" style="width: 10rem;">
              <p>Corrective Declaration fee: $12.88</p>
            </div>

            <p>A notice of Registration will be emailed to:
              <strong class="d-block">terrywood@gmail.com</strong>
            </p>

            <button class="btn btn-primary mt-2">Submit</button>
          </form>
        </div>

        <!-- Fee Summary -->
        <div class="col-md-4">
          <div class="card bg-medium border-0 sticky-top">
            <div class="card-body">
              <h3 class="card-title">Fee Summary</h3>

              <dl class="mt-2">
                <dt>File Reference</dt>
                <dd>12764 Smith</dd>
              </dl>

              <p><strong>3 Items</strong></p>

              <div class="d-flex justify-content-between">
                <span>Product Fee:</span>
                <span>$59.76</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Service Charge:</span>
                <span>$3.00</span>
              </div>

              <hr>

              <div class="d-flex justify-content-between">
                <span>Subtotal:</span>
                <span>$62.76</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>GST:</span>
                <span>$0.29</span>
              </div>

              <hr>

              <div class="d-flex justify-content-between">
                <strong>Total</strong>
                <strong>$63.05</strong>
              </div>

              <dl class="mt-2">
                <dt>GST Registration Number</dt>
                <dd>81630 4414 RT0001</dd>
              </dl>

              <router-link to="/pages/fee-details">Review fee details</router-link>
            </div>
          </div>
        </div>

      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Create Package"></help-panel>
  </main>
</template>

<script>

import PackageHeaderDefected from '../../components/PackageHeaderDefected.vue'
import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'package-header-defected': PackageHeaderDefected,
    'help-panel': HelpPanel,
  },

  data () {
    return {

    }
  }
}
</script>

